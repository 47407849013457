// Import certification
import certi1 from "./Assets/portfolio/certi-1.png";
import certi2 from "./Assets/portfolio/certi-2.png";
import certi3 from "./Assets/portfolio/certi-3.png";
import certi4 from "./Assets/portfolio/certi-4.png";
import certi5 from "./Assets/portfolio/certi-5.png";
import certi6 from "./Assets/portfolio/certi-6.png";
import certi7 from "./Assets/portfolio/certi-7.png";
import certi8 from "./Assets/portfolio/certi-8.png";
import certi9 from "./Assets/portfolio/certi-9.png";
import certi10 from "./Assets/portfolio/certi-10.png";
import certi11 from "./Assets/portfolio/certi-11.png";
import certi12 from "./Assets/portfolio/certi-12.png";
import certi13 from "./Assets/portfolio/certi-13.png";
import certi14 from "./Assets/portfolio/certi-14.png";
import certi15 from "./Assets/portfolio/certi-15.png";
import certi16 from "./Assets/portfolio/certi-16.png";
import certi17 from "./Assets/portfolio/certi-17.png";
import certi18 from "./Assets/portfolio/certi-18.png";
//Import Projects
import portfolio1 from "./Assets/portfolio/portfolio-1.png";
import portfolio2 from "./Assets/portfolio/portfolio-2.png";
import portfolio3 from "./Assets/portfolio/portfolio-3.png";
import portfolio5 from "./Assets/portfolio/portfolio-5.jpg";
import portfolio9 from "./Assets/portfolio/portfolio-9.jpg";
import portfolio10 from "./Assets/portfolio/portfolio-10.jpg";
import portfolio14 from "./Assets/portfolio/portfolio-14.jpg";
import portfolio15 from "./Assets/portfolio/portfolio-15.png";
import portfolio16 from "./Assets/portfolio/portfolio-16.png";
import portfolio17 from "./Assets/portfolio/portfolio-17.png";
import portfolio18 from "./Assets/portfolio/portfolio-18.png";
import portfolio19 from "./Assets/portfolio/portfolio-19.png";
import portfolio20 from "./Assets/portfolio/portfolio-20.png";
import portfolio21 from "./Assets/portfolio/portfolio-21.png";
import portfolio22 from "./Assets/portfolio/portfolio-22.png";

export const PROJECTS = [
  {
    image: portfolio1,
    name: "Crack Detection Mobile App",
    technologyUsed: "React Native, Flask API, YOLO V8",
    description:
      "Application for detect concrete cracks",
    url: "https://github.com/AIoT-Lab-VNU/Crack-Detection-Mobile-App",
  },
  {
    image: portfolio2,
    name: "Fall Detection",
    technologyUsed: "YOLO v8, DeepSort, Machine Learning",
    description:
      "Fall warning application via camera",
    url: "https://huggingface.co/spaces/ducdatit2002/fall-detection",
  },  
  {
    image: portfolio3,
    name: "LLM in SmartHouse",
    technologyUsed: "Llama 2, LLM",
    description:
      "",
    url: "",
  },
  {
    image: portfolio19,
    name: "EVALLOS",
    technologyUsed: "ReactJS, ShadCN, NodeJS, MongoDB",
    description:
      "An platform for evaluating students learning outcomes in university",
    url: "https://evallos.aiotlab.io.vn/",
  },
  {
    image: portfolio14,
    name: "IMBC2024",
    technologyUsed: "ReactJS, MUI, Ant Design",
    description:
      "Admin page and Landing page for Register Biochemistry Competition of University of Medicine and Pharmacy.",
    url: "https://imbc2024.com",
  },
  {
    image: portfolio15,
    name: "JEIFY JEWELRY",
    technologyUsed: "ReactJS, ThreeJS",
    description:
      "Landing page and 3D jewelry customization with chatbot LLM model. ",
    url: "https://jeify.netlify.app/",
  },
  {
    image: portfolio20,
    name: "AIAIR",
    technologyUsed: "ReactJS, Firebase, NodeJS",
    description:
      "Real-time Air Quality Monitoring System using Fog Computing Technology.",
    url: "https://www.aiair-aiotlabvn.tech/",
  },
  {
    image: portfolio22,
    name: "AiMA",
    technologyUsed: "Flutter",
    description:
      "AiMA is a cutting-edge artificial intelligence platform designed specifically for mathematics.",
    url: "https://play.google.com/store/apps/details?id=com.aiotlabvn.aima",
  },
  {
    image: portfolio21,
    name: "TechX",
    technologyUsed: "Tester",
    description:
      "Testing and Quality Assurance for The TechX Coding & Technology School Platform" ,
    url: "https://techx.edu.vn/",
  },
  {
    image: portfolio16,
    name: "AIoT Lab VN",
    technologyUsed: "ReactJS, TailwindCSS, Sanity Database",
    description:
      "Landing page website for AIoT Lab VN.",
    url: "https://aiotlab.vn/",
  },
  {
    image: portfolio17,
    name: "AI4IA Company",
    technologyUsed: "ReactJS, TailwindCSS, Sanity Database",
    description:
      "Landing page website for AI4IA.",
    url: "https://ai4ia.cc/",
  },
  {
    image: portfolio18,
    name: "Brainlife.",
    technologyUsed: "ReactJS, TailwindCSS",
    description:
      "Brain-Life: Understand Your Brain to Master Your Life",
    url: "https://brainlife.co.uk/",
  },
  {
    image: portfolio5,
    name: "2048 Game (with Undo feature)",
    technologyUsed: "HTML, CSS, JavaScript",
    description:
      "Application for Data Structures and Algorithms Courses.",
    url: "https://2048.phamducdat.id.vn",
  },
  {
    image: portfolio9,
    name: "Education visualization",
    technologyUsed: "HTML, CSS, JavaScript, D3.js",
    description:
      "Appication for Data Science and Visualization Courses.",
    url: "https://eduviz.phamducdat.id.vn",
  },
  {
    image: portfolio10,
    name: "360 VIRTUAL TOUR PLATFORM",
    technologyUsed: "HTML, CSS, JavaScript, Marzipano",
    description:
      "An realistic virtual tour experience through 360-degree images.",
    url: "https://trienlam360.iuyouth.edu.vn",
  },
];

export const PUBLICATIONS = [
  {
    type: "International Conferences",
    title: "HORUS: Multimodal Large Language Models Framework for Video Retrieval at VBS 2025",
    authors: "Tai Nguyen, Vo Ngoc Minh Anh, Duc Dat Pham, Tran Quang Vinh, Nhu Duong Thi Quynh, Le Anh Tien, Tan Duy Le, and Binh T. Nguyen*",
    info: "31st INTERNATIONAL CONFERENCE ON MULTIMEDIA MODELING (Rank B)",
    url: "https://mmm2025.net/conference/",
  },
  {
    type: "International Conferences",
    title: "A technical platform supporting the assessment of the level ofachievement of course learning outcomes, contributing to academic programquality assurance.",
    authors: "Duc Dat Pham, Mai Thanh Nguyen Quynh, Tan Duy Le*, Kha Tu Huynh*",
    info: "The Fourth International Conference on Material, Machines, and Methods for Sustainable Development (October, 2024)",
    url: "https://link.springer.com/conference/mmms",
  },
  {
    type: "International Conferences",
    title: "EVALLOS: An Effective Solution for CLO and SLO Assessment, Supporting Enhanced Teaching Quality",
    authors: "Duc Dat Pham, Mai Thanh Nguyen Quynh*, Ngoc Tram Huynh Thi, Tien Phat Le, Thien Huong Nguyen Trang, Nhan Pham Le Thanh",
    info: "The 2024 Conference on Applications of Technology, Automation, and Civil Engineering (ATAC 2024)",
    url: "https://atac2024.aiotlab.vn/",
  },
  {
    type: "International Conferences",
    title: "Multimodal Skin Lesion Classification With Convolutional Attention Mechanism",
    authors: "Nhan Le-Thanh Pham, Duc Dat Pham*",
    info: "The 2024 Conference on Applications of Technology, Automation, and Civil Engineering (ATAC 2024)",
    url: "https://atac2024.aiotlab.vn/",
  },
  {
    type: "International Conferences",
    title: "Tactiwave: AI-Driven Tactile Communication for Deaf-Blind Support",
    authors: "My Nguyen Huynh Thao, Nam Anh Dang Nguyen*, Duc Dat Pham*, Nguyen Binh Nguyen Le",
    info: "The 2024 Conference on Applications of Technology, Automation, and Civil Engineering (ATAC 2024)",
    url: "https://atac2024.aiotlab.vn/",
  },
  {
    type: "International Conferences",
    title: "SYNERGY: Smart System for Home Device Control and User Interaction",
    authors: "My Nguyen Huynh Thao, Duc Dat Pham, Tram Huynh Thi Ngoc, Nam Anh Dang Nguyen, Le Thanh Son*",
    info: "The 2024 Conference on Applications of Technology, Automation, and Civil Engineering (ATAC 2024)",
    url: "https://atac2024.aiotlab.vn/",
  },
  {
    type: "International Conferences",
    title: "NexMIND: AI-Driven Multimodal Integration for Neurological Diagnostics and Health Monitoring",
    authors: "Hung Tran Vu Khanh, Luan Nguyen Hoang*, Duc Dat Pham, My Nguyen Huynh Thao, Minh Khang Vo, Quang Tri Tran*",
    info: "The 2024 Conference on Applications of Technology, Automation, and Civil Engineering (ATAC 2024)",
    url: "https://atac2024.aiotlab.vn/",
  },
  {
    type: "Domestic Conferences",
    title: "IUVTOUR – IU VIRTUAL TOUR PLATFORM.",
    authors: "Duc Dat Pham, Chau Thi Thanh Truc, Nguyen Tran Minh Trung, Le Duy Duong, Le Duy Tan, Vo Thi Luu Phuong*",
    info: "THE SECOND SCIENTIFIC CONFERENCE OF GLOBAL VIETNAMESE YOUNG SCIENTISTS",
    url: "https://www.researchgate.net/publication/385689448_IUVTOUR_-_IU_VIRTUAL_TOUR_PLATFORM",
  },
  {
    type: "Domestic Conferences",
    title: "Fostering global citizenship among students and developing their digital skills in the context of a digital society.",
    authors: "Duc Dat Pham",
    info: "The 6th International Student Science Forum 2022 (ISSF 2022)",
    url: "https://www.researchgate.net/publication/385689728_RESEARCHING_THE_IMPACT_OF_COVID_-19_ON_DIGITAL_ECONOMY_-_DIGITAL_SOCIETY_IN_VIETNAM",
  },
  {
    type: "Domestic Conferences",
    title: "IUVTOUR – IU VIRTUAL TOUR PLATFORM.",
    authors: "Chau Thi Thanh Truc, Duc Dat Pham, Nguyen Tran Minh Trung, Le Duy Duong, Le Duy Tan, Vo Thi Luu Phuong*",
    info: "THE THIRD SCIENTIFIC CONFERENCE FOR STUDENTS OF INTERNATIONAL UNIVERSITY in 2022",
    url: "https://www.researchgate.net/publication/385690520_IUVTOUR-IU-VIRTUAL-TOUR_PLATFORM",
  },
];
export const EXPERIENCE = [
  {
    vitri: "Fullstack Developer and AI Researcher",
    company: "AIoT LAB VN",
    thoigian: "September(2023) - Present",
    mota: "Build applications and websites, research and develop AI models",
  },
  {
    vitri: "Website Technical Staff",
    company: "Seehan Digital Co., Ltd",
    thoigian: "July(2024) - November(2024)",
    mota: "Build and develop a website management system between Caller Devices and Automated Guided Vehicle",
  },
  {
    vitri: "Student, Reseacher",
    company: "INTERNATIONAL UNIVERSITY, VIETNAM NATIONAL UNIVERSITY",
    thoigian: "October(2021)-Present",
    mota: "GPA: 75/100",
  },
  {
    vitri: "Intern Fullstack Developer",
    company: "Cybersoft Academy",
    thoigian: "June(2023)-September(2023)",
    mota: "",
  }
];
export const CERTIFICATION = [
  {
    image: certi18,
    name: "5.5 IELTS Academic from IDP",
  },
  {
    image: certi16,
    name: "Professional Front-end Developer",
    url: "https://login.cybersoft.edu.vn/certification/?inf=eyJob1RlbiI6IlBo4bqhbSDEkOG7qWMgxJDhuqF0IiwidGVuTG9wIjoiQm9vdGNhbXAgNTQiLCJ0ZW5Mb1RyaW5oIjoiUFJPRkVTU0lPTkFMIEZST05ULUVORCBERVZFTE9QRVIiLCJkaWVtQ3VvaUtob2EiOjEwLjEsImlzWHVhdFNhYyI6dHJ1ZSwibWFDaHVuZ05oYW4iOiJGRSIsImlzSW5DaHVuZ05oYW4iOmZhbHNlLCJtYVNvQ2h1bmdOaGFuIjoxODk2LCJzb1RoYW5nSG9jIjoiNiIsIm5nYXlDYXBDaHVuZ05oYW4iOiIyMDI0LTAzLTE0VDA5OjE0OjI1IiwiZnVsbE1hQ2h1bmdOaGFuIjoiRkUvMjAyNC8yMDI0MTg5NiIsIm1hVGhvbmdUaW5Ib2NUYXAiOjM4MDUsImlzQmFvVmVDdW9pS2hvYSI6dHJ1ZX0=",
  },
  {
    image: certi17,
    name: "Professional NodeJS Developer",
    url: "https://login.cybersoft.edu.vn/certification/?inf=eyJob1RlbiI6IlBo4bqhbSDEkOG7qWMgxJDhuqF0IiwidGVuTG9wIjoiTm9kZUpTIDM3IiwidGVuTG9UcmluaCI6IlBST0ZFU1NJT05BTCBOT0RFIEpTIERFVkVMT1BFUiIsImRpZW1DdW9pS2hvYSI6OS45LCJpc1h1YXRTYWMiOnRydWUsIm1hQ2h1bmdOaGFuIjoiTkUiLCJpc0luQ2h1bmdOaGFuIjpmYWxzZSwibWFTb0NodW5nTmhhbiI6MTg2OSwic29UaGFuZ0hvYyI6IjMiLCJuZ2F5Q2FwQ2h1bmdOaGFuIjoiMjAyNC0wMi0xMFQwMzowMDowOSIsImZ1bGxNYUNodW5nTmhhbiI6Ik5FLzIwMjQvMjAyNDE4NjkiLCJtYVRob25nVGluSG9jVGFwIjo0NTgyLCJpc0Jhb1ZlQ3VvaUtob2EiOmZhbHNlfQ==",
  },
  {
    image: certi1,
    name: "CS50 Course",
    url: "https://certificates.cs50.io/153234f7-ee2d-4bb1-b88b-8329328d8479.pdf?size=letter",
  },
  {
    image: certi12,
    name: "IoT Fundamentals: Connecting Things",
    url: "https://drive.google.com/file/d/1bOgmG1SXauyM4Jw_SY5uhHYFQmz04W-_/view?usp=drive_link",
  },
  {
    image: certi2,
    name: "Information Visualization: Programming with D3.js ",
    url: "https://www.coursera.org/account/accomplishments/verify/6JAXGUADYDSA",
  },
  {
    image: certi3,
    name: "Foundations of User Experience (UX) Design",
    url: "https://www.coursera.org/account/accomplishments/verify/58SKP6N7BTBG?utm_source=link&utm_medium=certificate&utm_content=cert_image&utm_campaign=sharing_cta&utm_product=course",
  },
  {
    image: certi4,
    name: "Basic python programming",
    url: "https://tek4.vn/chung-chi/chi-tiet/1186",
  },
  {
    image: certi6,
    name: "HTML/CSS Course",
    url: "https://fullstack.edu.vn/cert/o3rob",
  },
  {
    image: certi5,
    name: "Advanced C++",
    url: "https://codelearn.io/certification/zmq4zjuw",
  },
  {
    image: certi7,
    name: "Consolation prizes of The 25th Eureka Scientific Research Award 2023",
  },
  {
    image: certi8,
    name: "Achieved the title of 5-Good Student from Vietnam National University",
  },
  {
    image: certi15,
    name: "Achieved the title of 5-Good Student from International University",
  },
  {
    image: certi9,
    name: "Participated in THE 6H INTERNATIONAL STUDENT SCIENCE FORUM 2022",
  },
  {
    image: certi10,
    name: "Submitting paper and participating in THE THIRD SCIENTIFIC CONFERENCE FOR STUDENTS",
  },
  {
    image: certi11,
    name: "Participated in The 24th Eureka Scientific Research Award 2022",
  },
  {
    image: certi14,
    name: "Participated in The 25th Eureka Scientific Research Award 2023",
  },
  {
    image: certi13,
    name: "Participated in DIGITRANS SMART CITY Competition 2023",
  },
];

// export const SKILLS = [
//   { name: "TypeScript || JavaScript || ReactJs || React Native" },
//   { name: "Nodejs || ExpressJS || Socket.io || NestJS || Graphql" },
//   { name: "MySQL || MongoDB" },
//   { name: "AntD || Bootstrap || Material UI |  TailwindCSS || ShadCN || DaisyUI" },
//   { name: "Large Language Model" },
//   { name: "Machine Learning || Computer Vision" },
// ];

// export const TOOLS = [
//   "Git",
//   "Ubuntu Server",
//   "Digital Ocean",
//   "AWS",
// ];


